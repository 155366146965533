import { useContext, useEffect, useState } from "react";
import StoreContext from "../context/StoreContext";
import { v2Api } from "../api/v2.api";
import AdminContext from "../context/AdminContext";

const useFetchInitialData = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { setStoreStates } = useContext(StoreContext);
  const { setAdminState } = useContext(AdminContext);

  useEffect(() => {
    if (window.location.pathname !== "/signin") {
      callMethods();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  async function callMethods() {
    setIsLoading(true);

    // getting the token from the local storage
    const LC_TOKEN =
      localStorage?.getItem("ACCESS_TOKEN") ||
      window.location.search.split("?t=")[1];

    if (!LC_TOKEN) {
      window.location.href = "/signin";
      return;
    }

    // const hubBoundaries = await axios.get("/hubBoundaries.json");
    let initialAdminData = await v2Api({
      operation: "adminController",
      tags: ["admin"],
      token: LC_TOKEN,
    });

    // the admin is not authenticated
    if (initialAdminData.status === 401) {
      localStorage.clear();
      window.location.href = "/signin";
      return;
    }

    // if the admin data is not fetched successfully
    if (!initialAdminData.isSuccess) {
      window.showMsg("Error while fetching the admin pannel data");
      return;
    }
    initialAdminData = JSON.parse(initialAdminData.data);

    // setting up the admin information in the state
    setAdminState((p) => ({
      ...p,
      admin: LC_TOKEN,
      adminInfo: initialAdminData?.admin,
    }));

    let allHubs = [];
    const hubCityPairs = {};
    for (const curr of Object.values(initialAdminData.serviceableCities)) {
      allHubs.push(...curr.hubIds);
      for (const hub of curr.hubIds) {
        hubCityPairs[hub] = curr.city;
      }
    }

    const decorCategoryList = initialAdminData?.categoriesList.find(
      (category) => category._id === "decorCategory"
    )?.miscData[0]?.info;

    const addonCategoryList = initialAdminData?.categoriesList.find(
      (category) => category._id === "addonCategory"
    )?.miscData[0]?.info;
    // console.log(
    //   Object.values(initialAdminData.serviceableCities)?.map((_) => {
    //     return { ..._, _id: _.city };
    //   })
    // );
    setStoreStates((p) => ({
      ...p,
      hubsByCity: Object.values(initialAdminData.serviceableCities)?.map(
        (_) => {
          return {
            ..._,
            _id: _.city,
            hubs: _.hubIds?.map((hub) => {
              return { _id: hub, city: _.city, name: hub };
            }),
          };
        }
      ),
      hubs: allHubs,
      allHubs: allHubs,
      defaultHub: { _id: allHubs[0] },
      hubCityPairs: hubCityPairs,
      decorCategoryList,
      addonCategoryList,
      admins: initialAdminData?.admins,
      expert: {
        activeExperts: initialAdminData?.admins?.filter(
          (admin) => admin.accountStatus === "active"
        ),
      },
      hubBoundaries: initialAdminData.hubBoundaries?.data?.features || [],
      serviceableCities: initialAdminData.serviceableCities,
    }));

    setIsLoading(false);
  }

  return { isLoading };
};

export default useFetchInitialData;
